@import url('https://fonts.googleapis.com/css2?family=Piedra&family=Russo+One&display=swap');

html {
  scroll-behavior: smooth;
}

#main-logo {
  width: 1.5in;
}

.trail-main-heading {
  width: 60%;
}

.granite-access-btn {
  width: 200px;
  transition: 0.2s ease;
}

.granite-access-btn:hover {
  transform: scale(1.1);
}

.login-page {
  margin: 50px 0;
}

* {
  font-family: 'Russo One', sans-serif;
}

body {
  background: #1CABD8;
}

.navbar {
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
}

.currency-box {
  border-radius: 10px;
  background: #356431;
  display: flex;
  padding: 2px 6px;
  justify-content: space-between;
  align-items: center;
}

.currency-value {
  color: #E8C304;
  font-family: 'Russo One', sans-serif;
  font-size: 25px;
  margin-top: 2px;
}

.currency-icon {
  width: 25px;
  height: 25px;
}

.currency-value,
.currency-icon {
  margin-left: 3px;
}

.currency-retry-button {
  color: red;
  font-size: 28px;
  background-color: white;
  height: 40px;
  padding: 0 5px;
  border-radius: 5px;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 158px;
  z-index: 1;
}

.nav-link {
  margin: 0 20px;
  font-family: 'Russo One', sans-serif;
  color: #fff;
  font-size: 24px;
  transition: 0.2s ease;
}

.nav-link:hover{
  color: #ffffffaf;
}

.trail-main-heading-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 100px;
  padding-bottom: 100px;
}

.trail-main-h1-container {
  display: flex;
  justify-content: center;
}

.trail-main-left {
  position: relative;
  width: 225px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.main-heading {
  font-size: 30px;
  width: 100px;
  font-weight: 400;
  line-height: 35px;
}

.main-description {
  text-align: center;
  font-size: 9px;
}

.main-heading,
.main-description {
  color: #fff;
}

.mountain-gif {
  width: 100%;
}

.products-section {
  background-color: #F3F3F3;
}

.items-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  padding-bottom: 50px;
}

.general-store-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.general-store-logo {
  width: 30%;
}

.general-store-heading {
  font-size: 30px;
  color: #333;
}

.retry-fetch-project-btn-container {
  text-align: center;
  padding: 50px 0;
}

.retry-fetch-projects-button {
  padding: 3px 5px;
  background-color: red;
  color: white;
  border-radius: 3px;
}

.single-item-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #9cc055;
  margin: 30px auto 0 auto;
  padding: 30px 0 0 0;
  width: 200px;
  border-radius: 5px;
  box-shadow: 3px 3px 5px 6px #ccc;
  position: relative;
  transition: ease 0.1s;
}

.single-item-img-container {
  display: flex;
  justify-content: center;
  height: 65%;
  padding: 0 10px;
  transition: 0.2s ease;
}

.single-item-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.single-item-price-container {
  display: flex;
  justify-content: center;
  padding-top: 5px;
  width: 100%;
  padding: 10px 0;
  background-color: #83B02A;
}

.single-item-price-logo {
  width: 25px;
  height: 25px;
  margin-right: 7px;
}

.single-item-name,
.single-item-price {
  color: white;
  font-size: 17px;
  text-align: center;

}

.single-item-stock-container {
  display: flex;
  margin: 5px;
  align-items: center;
}

.single-item-stock-item {
  margin: 2px;
}

.single-item-stock-icon {
  width: 25px;
  height: 25px;
}

.single-item-stock-quantity {
  color: white;
}

.claim {
  height: 45px;
  color: white;
  margin-top: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  visibility: hidden;
  font-size: 18px;
}

.single-item-container:hover .claim {
  visibility: inherit;
}

.enable {
  background-color: #356431;
}

.disable {
  background-color: #7F7F7F;
}

.popup-product {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1CABD8;
  background-image: url(./assets/purchase_clouds.png), url(./assets/purchase_character.png);
  background-position: right top, left bottom;
  background-size: 50%;
  background-repeat: no-repeat;
  z-index: 10;
}

.currency-value-container {
  min-width: 103px;
  width: 10%;
  position: fixed;
  top: 20px;
  left: 20px;
}

.purchase-form {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  margin-top: 100px;
}

.purchase-form-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hidden-field {
  position: absolute;
  top: 0;
  visibility: hidden;
}

.purchase-form-inp {
  margin-top: 5px;
  padding-left: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  width: 300px;
  border: none;
  outline: none;
  background-color: #ffffffe9;
  transition: 0.2s ease;
  color: #333333;
}

.purchase-form-inp:focus{
  padding-left: 10px;
}

.popup-product-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popup-product-image {
  width: 300px;
  height: 300px;
  object-fit: contain;
  margin: auto;
}

.popup-product-name {
  color: white;
  font-size: 24px;
  text-align: center;
}

.popup-product-price-container {
  display: flex;
  justify-content: center;
}

.popup-product-submit-btn {
  width: 150px;
  height: 30px;
  color: white;
  margin: auto;
  border-radius: 5px;
  background: #9CC055;
  cursor: pointer;
}

.back-btn-container {
  margin-top: 10px;
}

.product-modal-navigation-btn,
.back-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-modal-navigation-btn-polygon {
  width: 12px;
  margin-right: 2px;
}

.product-modal-navigation-btn-text {
  transition: 0.2s ease;
  color: white;
}
.product-modal-navigation-btn-text:hover{
  margin-left: 5px;
}

.purchased-item-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.purchased-item {
  border-radius: 10px;
  background: #9CC055;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  width: 300px;
  margin-bottom: 30px;
}

.purchased-item-image {
  width: 250px;
}

.purchased-item-text {
  color: white;
  font-size: 20px;
}

.purchased-item-congrats-image {
  width: 100%;
}

.spinner-overlay {
  background-color: rgba(156, 192, 185, 0.5);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-popup {
  width: 300px;
  height: 300px;
  background-color: #9cc055;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 5px solid white;
}

.error-popup-close-btn {
  align-self: flex-end;
}

.error-popup-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
}

.error-popup-sign {
  font-size: 70px;
}

.error-popup-title {
  font-size: 24px;
}

.error-popup-desc {
  font-size: 18px;
}

.error-popup-close-btn,
.error-popup-title,
.error-popup-desc,
.error-popup-sign {
  color: white;
}
.spinner-products {
  display: flex;

  justify-content: center;
  align-items: center;
}

.footer {
  padding: 30px 0 0 0;
  background-image: url(./assets/bird.gif);
  background-repeat: no-repeat;
  background-size: 100%;
}

.footer-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-heading {
  text-align: center;
  color: white;
  font-size: 28px;
}

.social-icons-container {
  display: flex;
  justify-content: space-around;
}

.footer-arrow-image,
.social-icons-container,
.footer-clouds-image {
  width: 50%;
}

.social-icons {
  width: 25px;
  opacity: 0.5;
  transition: 0.2s ease;
}

.social-icons:hover {
  transform: scale(1.1);
}

.footer-rights {
  color: white;
  font-size: 6px;
}

.login-page {
  display: flex;
  justify-content: center;
}

.login-container {
  display: flex;
  height: 80vh;
  width: 60%;
}

.login-page-info {
  background: white;
  border-radius: 5px 0 0 5px;
}

.login-page-btn {
  background-color: #9CC055;
  width: 100%;
  border-radius: 0 5px 5px 0;
}

.login-page-info,
.login-page-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-page-heading {
  font-size: 25px;
  color: #E8C304;
  font-family: 'Piedra', serif;
}

.login-page-btn-label {
  color: white;
  font-size: 30px;
  font-family: 'Piedra', serif;
}

.main-page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: white;
}

.arrow-down {
  margin: 7px 0;
  width: 20px;
}

.logout-btn {
  background-color: white;
  padding: 5px 10px;
  border-radius: 4px;
  color: #333;
}

.logout-btn:hover {
  background-color: #ccc;
}

.logout-btn:focus {
  background-color:rgb(156, 156, 156);
}

.hidden-logout-btn {
  display: none;
  transition: all ease-in-out 1s;
}

.logout-container:hover .hidden-logout-btn {
  display: flex;
  transition: all ease-in-out 1s;
}

.logout-container,
.hidden-logout-btn {
  flex-direction: column;
  align-items: center;
}

.logout-container {
  display: flex;
}

.arrow-more-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.arrow-more-btn-text {
  color: #505050;
  font-size: 15px;
}

.arrow-more-btn-icon {
  width: 50px;
  transition: all ease-in-out 0.2s;
}

.arrow-more-btn-icon:hover {
  width: 80px;
}


::-webkit-scrollbar {
  width: 10px;
}
 
::-webkit-scrollbar-track {
  background-color: #e4e4e4;
}
 
::-webkit-scrollbar-thumb {
  background-color: #83b02a;
}

.support-btn-container {
  display: flex;
  align-items: flex-end;
  text-align: center;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.support-extra-buttons-container {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  transition: all 0.45s ease-in-out;
}

.support-extra-buttons-container-hidden {
  transform: translateX(550%);
  position: fixed;
  right: -50%;
  margin-right: 5px;
}

.hide-delay {
  display: none;
}

.support-btn-green {
  background-color: #356431;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: all ease-in-out 0.25s;
}

.support-btn-green:hover {
  background-color: #5D8359;
  transition: all ease-in-out 0.25s;
}

.support-btn {
  margin-top: 3px;
  color: #E8C304;
  font-size: 25px;
}

.support-extra-button {
  background-color: #5D8359;
  padding: 10px;
  color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.support-extra-button:hover {
  background-color: #356431;
}

.support-extra-button:focus {
  background-color: #275323;
}

.support-extra-button-margin {
  margin-bottom: 5px;
}

.support-extra-button-icon {
  margin-right: 3px;
}

.baseline-icon {
  width: 80px;
  margin-left: 12px;
  align-self: flex-start;
}

.support-extra-earn-trail-button {
  border-radius: 5px 50px 50px 5px;
}

@media screen and (max-width: 300px) {
  .purchase-form-inp {
    width: 80%;
  }
  
  .popup-product-image {
    width: 80%;
    height: 280px;
  }

  .footer {
    background-position-y: 70px;
  }
}

@media screen and (max-width: 700px) {
  .nav-links {
    animation-delay: 1ms;
    display: none;
  }

  .nav-link {
    background-color: white;
    padding: 5px 10px;
    border-radius: 4px;
    color: #333;
    margin-top: 5px;
    font-size: 17px;
    transition: 0.2s ease;
  }

  .logout-container:hover + .nav-links {
    display: inherit;
  }

  .navbar,
  .nav-links {
    flex-direction: column;
    align-items: center;
  }

  .logout-container {
    margin: 0 10px;
  }
  
  .footer {
    background-position-y: 50px;
  }
}

@media screen and (min-width: 768px) {  
  .currency-box {
    border-radius: 10px;
    background: #356431;
    display: flex;
    padding: 2px 10px;
    justify-content: space-between;
    align-items: center;
  }
  
  .currency-value {
    color: #E8C304;
    font-family: 'Russo One', sans-serif;
    font-size: 25px;
    margin-top: 2px;
  }
  
  .currency-icon {
    width: 25px;
    height: 25px;
  }
  
  .currency-value,
  .currency-icon {
    margin-left: 3px;
  }
  
  .nav-links {
    position: static;
  }
  
  .nav-link {
    margin: 11px 20px;
    font-family: 'Russo One', sans-serif;
    color: #fff;
    font-size: 24px;
    transition: 0.2s ease;
  }

  .trail-main-heading-container {
    flex-direction: row;
  }

  .trail-main-left {
    width: auto;
  }

  .main-heading {
    font-size: 50px;
    line-height: 55px;
  }

  .main-description {
    font-size: 13px;
  }
  
  .meta-mountain-logo {
    margin-top: 2px;
    width: 160px;
    height: 43px;
  }

  .mountain-gif {
    width: 40%;
  }

  .general-store-heading {
    font-size: 64px;
  }

  .popup-product {
    padding: 50px 50px 75px 50px;
  }

  .popup-product-image {
    height: 280px;
  }

  .currency-value-container {
    position: static;
  }

  .purchase-form {
    grid-template-columns: 1fr 1fr;
    margin-top: 0;
  }

  .purchase-form-inputs {
    align-self: flex-start;
    justify-self: start;
    margin-top: 7vw;
    margin-left: 6vw;
  }

  .back-btn-container {
    margin-top: 30px;
  }

  .social-icons {
    width: 0.5in;
    transition: 0.2s ease;
  }

  .footer-rights {
    font-size: 20px;
  }
}
